import React from "react";
import Highlight from 'react-highlight';
import { ItemQuestionType } from "../entity/question";
const _ItemQuestion = (props: {
    item: ItemQuestionType, 
    index: number,
    handleAnswer: (value: number | number[] | "other" | null, other: string, id: number) => void
}) => {
    const alphas: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    const [value, setValue] = React.useState<number | number[] | 'other' |  null>(null);
    const [other, setOther] = React.useState<string >('');
    const inputRef = React.useRef<HTMLInputElement>(null);
    const {item, handleAnswer} = props;
    const replaceCode = (value: string) => {
        let stringReplace;
        value.match(/<\s*code[^>]*>(.*?)<\s*\/\s*code>/g)?.map(string => {
            const oldString = string;
            const newString = string.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('&lt;code&gt;', '<code>').replaceAll('&lt;/code&gt;', '</code>');
            return stringReplace = value.replaceAll(oldString, newString);
        });
        return stringReplace ?? value;
    }
    React.useEffect(() => {
        handleAnswer(value, other, item.id);
        //eslint-disable-next-line
    }, [value, other, item]);
    return (
        <div className={`test-form__item index-${props.index + 1}`}>
            <Highlight className='language-name-of-snippet'>
                <div className={`test-form__question${props.item.isRequired ? ' required' : ''}`} dangerouslySetInnerHTML={{__html: props.item.question}} />
            </Highlight>
            <div className="test-form__answer">
                {props.item.type !== 'input'
                 ? <>
                    {props.item.answer.map((answer, idx) =>
                        <div className="flex sm:items-center items-start mb-1.5" key={idx}>
                            <input 
                                type={props.item.type} 
                                id={`q${props.index}-${idx}`} 
                                name={item.id.toString()}
                                value={answer.id}
                                checked={Array.isArray(value) ? value.find(x => x === answer.id) !== undefined : answer.id === value}
                                onChange={(e) => {
                                    if(e.target.type === 'radio') {
                                        setValue(answer.id)
                                    }else {
                                        if(e.target.checked) {
                                            setValue(value === null ? [answer.id] : Array.isArray(value) ? [...value, answer.id] : null)
                                        }else {
                                            setValue(value === null ? [answer.id] : Array.isArray(value) ? value.filter(x => x !== answer.id) : null)
                                        }
                                    }
                                }}
                            />
                            <label htmlFor={`q${props.index}-${idx}`} className="flex sm:items-center items-start">{alphas[idx]}.<div className="ml-1" dangerouslySetInnerHTML={{__html:replaceCode(answer.text) ?? 'aa'}}></div></label>
                        </div>
                    )}
                    {props.item.hasOther &&
                        <div >
                            <label htmlFor={`q${props.index}-${props.item.answer.length}`} className="flex sm:items-center items-start mb-2">
                                <input 
                                    type={props.item.type} 
                                    id={`q${props.index}-${props.item.answer.length}`} 
                                    name={item.id.toString()}
                                    value={'other'}
                                    ref={inputRef}
                                    checked={value === 'other'}
                                    onChange={() => setValue('other')}
                                />
                                <div className="extend">
                                    {alphas[props.item.answer.length]}.
                                    <div className="ml-1 w-full">
                                        <input 
                                            type="text" 
                                            className="form-input__text --extend" 
                                            name={item.id.toString()}
                                            value={other}
                                            onChange={(e) => setOther(e.target.value)}
                                            onFocus= {()=> setValue('other')} 
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                    }
                 </>
                 : item.size === '1'
                  ? <input 
                        type="text" 
                        className="form-input__text --extend" 
                        name={`question-${props.index}`} 
                        onChange={(e) => setOther(e.target.value)}
                        onFocus= {()=> setValue('other')} 
                    />
                  : <textarea 
                    id={`q${props.index}`} 
                    name={`question-${props.index}`} 
                    placeholder="Please text here"
                    onChange={(e) => setOther(e.target.value)}
                    onFocus= {()=> setValue('other')}
                    rows={parseInt(item.size)}
                    ></textarea>
                }
                
            </div>
        </div>
    );
};

export const ItemQuestion = React.memo(_ItemQuestion);