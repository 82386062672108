export class Route {

  static readonly NotFound = {
    path: '/'
  };
  static readonly Home = {
    path: '/test/:id'
  };
  static readonly HomeSurvey = {
    path: '/survey/:id'
  };
  static readonly HomeQuiz = {
    path: '/quiz/:id'
  };
  static readonly HomeGame = {
    path: '/game/:id'
  };
  static readonly Completed = {
    path: '/completed'
  };
}