import logo from '../assets/images/logo.png';
import completed from '../assets/images/completed.jpg';
import completedSp from '../assets/images/completed-sp.jpg';
import completedSurvey from '../assets/images/completed_survey.jpg';
import completedSurveySp from '../assets/images/completed_survey_sp.jpg';
import line from '../assets/images/line.jpg';
import * as ReactRouter from "react-router-dom";

export const Completed = () => {
  const location = ReactRouter.useLocation();
  const {pathname} = location;
  return (
    <div id="tmp_completed">
      <div className="container">
        <div className="flex flex-col items-center pt-10 sm:px-0 px-2">
          <p className="sm:mb-12 mb-10">
            <img src={logo} alt="Logo"/>
          </p>
          <p className="sm:mb-16 mb-12">
            <picture>
              <source media="(max-width:640px)" srcSet={pathname.indexOf('survey') !== -1 ? completedSurveySp : pathname.indexOf('test') !== -1 ? completedSp : completedSp} />
              <img src={pathname.indexOf('survey') !== -1 ? completedSurvey : pathname.indexOf('test') !== -1 ? completed : completed} alt="completed" />
            </picture>
          </p>
          {pathname.indexOf('survey') === -1 &&
            <p className="sm:mb-10 mb-6 sm:text-xl text-sm text-center">We will check and feedback to you soon<br />The result will be sent to the email you input, so please check it.</p>
          }
          <p><img src={line} alt="line"/></p>
        </div>
      </div>
    </div>
  );
};