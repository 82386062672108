import React from 'react';
import * as ReactRouter from "react-router-dom";
import { api } from '../function/api';
import * as Components from '../components';
import 'highlight.js/styles/vs2015.css';
import Swal from 'sweetalert2'
import { ItemQuestionType, RequestSubmitAnswerBody } from '../entity/question';

const CountDown = React.forwardRef((props: {
  time: string, 
  setFinishTime: React.Dispatch<React.SetStateAction<boolean>>,
}, ref: React.Ref<{}>) => {
  const [second, setSecond] = React.useState<number>(parseInt(props.time.split(':')[2]));
  const [minute, setMinute] = React.useState<number>(parseInt(props.time.split(':')[1]));
  const [hours, setHours] = React.useState<number>(parseInt(props.time.split(':')[0]));
  let timerString = `${hours > 9 ? hours : '0'+ hours}:${minute > 9 ? minute : '0'+minute}:${second > 9 ? second : '0'+second}`;
  const [firstLoad, setFristLoad] = React.useState<boolean>(timerString === props.time);
  React.useImperativeHandle(ref, () => ({
    getValues: () => timerString
  }));
  React.useEffect(() => {
    if(second === 0 && minute === 0 && hours === 0) {
      return props.setFinishTime(true);
    }else if (firstLoad) {
      const timeout = setTimeout(() => setFristLoad(false), 1000);
      return () =>  clearTimeout(timeout);
    }else {
      if(second > 0 ) {
        const timer = setInterval(() => setSecond(second - 1),1000);
        return () => clearInterval(timer);
      }else {
        if(minute > 0 ) {
          setSecond(59);
          setMinute(minute - 1);
        }else {
          if(hours > 0) {
            setSecond(59);
            setMinute(59);
            setHours(hours - 1);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [second, minute, hours, firstLoad]);
  return (
    <span className="yellow">{timerString}</span>
  )
})
export const Test = (props: {
  name?: string,
  email?: string,
  status: "PROCESSING" | "INIT" | "SUCCESS" | "FAILED",
  setStatus: React.Dispatch<React.SetStateAction<"PROCESSING" | "INIT" | "SUCCESS" | "FAILED">>,
  backTopRef: React.MutableRefObject<HTMLButtonElement | null>,
}) => {
  const testId = ReactRouter.useParams();
  const location = ReactRouter.useLocation();
  const {pathname} = location;
  const [data, setData] = React.useState<'loading' | any>('loading');
  const headerBarRef = React.useRef<HTMLDivElement>(null);
  const questNuRef = React.useRef<number>(0);
  const numberRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const positionRef = React.useRef<number>(0);
  const [answers, setAnswers] = React.useState<{id: number, idAnswer: number | number[] | null | string, isRequired: null | boolean}[]>([]);
  const timerRef = React.useRef<{getValues : () => string | null}>({getValues : () => null});
  const [finishTime, setFinishTime] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<number>(new Date().getTime());
  const [point, setPoint] = React.useState<{type: 'QUIZ' | 'GAME', point: number} | null>(null);
  const fixedItem = React.useCallback(() => {
    const scrollTop = window.scrollY;
    const topHaftWindow = scrollTop + window.innerHeight/2;
    const right = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 - 36 : 0;
    if(headerBarRef.current !== null && contentRef.current !== null) {
      const header =  headerBarRef.current;
      const content = contentRef.current
      if(header.offsetTop > 0) {
        positionRef.current = header.offsetTop;
        questNuRef.current = content.offsetTop + 150;
      };
      if(scrollTop >= positionRef.current) {
        content.style.paddingTop = header.clientHeight + (window.innerWidth > 640 ? 30 : 10) + 'px'
        header.classList.add('fixed');
      }else {
        header.classList.remove('fixed');
        content.style.paddingTop = (window.innerWidth > 640 ? 30 : 10) + 'px'
      }
    }
    if(numberRef.current !== null) {
      if(questNuRef.current > 0 && topHaftWindow >= questNuRef.current && window.innerWidth > 640) {
        numberRef.current.style.right = right + 'px'
        numberRef.current.classList.add('fixed');
      }else {
        numberRef.current.classList.remove('fixed');
        numberRef.current.style.right = '';
      }
    }
  }, []);
  const checkAnswers = React.useCallback((value: number | number[] | "other" | null, other: string, id: number) => {
    if(typeof value === 'number' || Array.isArray(value)) {
      setAnswers([...(answers.map(x => x.id === id ? { id: id, idAnswer: value, isRequired: x.isRequired} : x))])
    }else if(value === 'other') {
        setAnswers([...(answers.map(x => x.id === id ? { id: id, idAnswer: other !== '' ? other : null, isRequired: x.isRequired} : x))])
    }
  }, [answers])
  React.useEffect(() => {
    window.addEventListener('scroll', fixedItem);
    window.addEventListener('resize', fixedItem);
    return () => {
        window.removeEventListener('scroll', fixedItem);
        window.removeEventListener('resize', fixedItem);
    };
  },[fixedItem]);
  const scrollToTop = () => {
    window.scrollTo({ top: positionRef.current > 0 ? positionRef.current : headerBarRef.current?.offsetTop, behavior: 'smooth' });
  };
  React.useEffect(() => {
    if(props.backTopRef?.current && headerBarRef.current) {
      props.backTopRef?.current.addEventListener("click", scrollToTop);
      return () => {
        props.backTopRef?.current?.removeEventListener("click", scrollToTop);
      }
    }
  })
  const caseMessage = (res: number) => {
    switch(res) {
      case 200:
        return ''
      case 404:
        return 'Test was not found'
      case 405:
        return "The test has already been taken"
      case 500:
        return 'Internal server'
      default:
        return 'Wrong parameter'
    }
  }
  React.useEffect(() => {
    if(testId && testId.id && data === 'loading') {
      const getTest = api.getTest(testId.id, props.name ?? '', props.email ?? '');
      getTest.then(res => {
        if(typeof res === 'string' && res !== 'error') {
          const resJson = JSON.parse(res);
          if(resJson.type === null || pathname.indexOf(`/${resJson.type}/${testId.id}`) !== -1) {
            setAnswers((resJson.type !== 'game' && resJson.type !== 'quiz') ? [...resJson.questions.map((x: ItemQuestionType) => ({id: x.id, idAnswer: null, isRequired: x.isRequired}))] : [{}]);
            (resJson.isOnce && testId.id) && localStorage.setItem('tested_'+testId.id, resJson.isOnce);
            if(props.backTopRef?.current) props.backTopRef.current.style.display = '';
            setStartTime(new Date().getTime());
            return setData(resJson)
          }else {
            Swal.fire({
              title: caseMessage(404),
              confirmButtonText: 'OK',
              confirmButtonColor: '#0069bd',
            }).then((result) => {
              if (result.isConfirmed || result.dismiss) {
                window.location.reload();
              }
            })
          }
        }else {
          if(props.backTopRef?.current) props.backTopRef.current.style.display = 'none';
          Swal.fire({
            title: caseMessage(res.code),
            confirmButtonText: 'OK',
            confirmButtonColor: '#0069bd',
          }).then((result) => {
            if (result.isConfirmed || result.dismiss) {
              window.location.reload();
            }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, []);
  const onSubmitTest = () => {
    const timeGet = timerRef.current.getValues();
    const currentTime = new Date().getTime();
    if(props.name && props.email && (timeGet !== null || data.timeLimit === '00:00:00' || data.timeLimit === '') && testId.id) {
      if(point === null) props.setStatus('PROCESSING');
      const requestBody: RequestSubmitAnswerBody = {
        id: testId.id,
        name:  props.name,
        email: props.email,
        time: timeGet ?? ((data.type === 'quiz' || data.type === 'game') ? new Date(currentTime - startTime).toISOString().slice(11, 19) : '00:00:00'),
        answers: answers.map(x => ({id: x.id, answer: x.idAnswer})),
        point: point?.point ?? null
      }
      const postTest = api.postTest(requestBody);
      postTest.then(res => {
        const resMessage = caseMessage(res.code);
        if(resMessage === '') {
          if(point === null) props.setStatus('SUCCESS');
        }else {
          props.setStatus('FAILED');
          Swal.fire({
            title: caseMessage(res.code),
            confirmButtonText: 'OK',
            confirmButtonColor: '#0069bd',
          });
        }
      })
    }
  }
  React.useEffect(() => {
    if(point !== null) {
      onSubmitTest();
    }
  },[point])
  React.useEffect(() => {
    if(finishTime && data.questions.length > 0 && data.timeLimit !== '00:00:00' && data.timeLimit !== '' && props.status === 'INIT') {
      props.setStatus('PROCESSING');
      Swal.fire({
        title: 'Timeout for test!',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0069bd',
      }).then((result) => {
        if (result.isConfirmed || result.dismiss) {
          onSubmitTest();
        }
      })
    }
  }, [finishTime, data, props.status])
  return (
    <div id="tmp_test">
      {data === 'loading'
        ? <Components.Loading size={30} />
        : <>
          <div className="header-bar" ref={headerBarRef}>
            <div className="container flex sm:flex-row flex-col justify-between sm:items-center">
              <div className="name">
                  <span>Hello :</span>
                  <span className="yellow">{props.name}</span>
              </div>
              {data.type !== 'quiz' && data.type !== 'game' &&
                <div className="flex sm:items-center sm:flex-row flex-col">
                  <div className="time">
                    {data.timeLimit !== '00:00:00' && data.timeLimit !== '' &&
                      <>
                        <span>Total time remaining</span>
                        <CountDown time={data.timeLimit ?? '00:00:00'} setFinishTime={setFinishTime} ref={timerRef}/>
                      </>
                    }
                  </div>
                  <div className="question">
                    <span>Questions:</span>
                    <span className="yellow">{(data !== 'loading' && data.questions) ? data.questions.length : 0}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          {props.status === 'PROCESSING'
            ? <Components.Loading size={50} full={true} />
            : <div className="content view-bg" ref={contentRef}>
              { data.type === 'quiz'
              ? <Components.Quiz data={data} setPoint={setPoint} setStartTime={setStartTime}/>
              : data.type === 'game'
              ? <Components.Game data={data} setPoint={setPoint} setStartTime={setStartTime}/>
              : <div className="test-wrap">
                    <p className="d-flex text-rose-600 font-bold text-lg italic mb-8 text-warning"> You will retake the test if reload the browser without submitting it!</p>
                    <div className="test__number" ref={numberRef}><span className="sm:hidden">Answer the question:</span> {answers.filter(x => (x.idAnswer !== null && !Array.isArray(x.idAnswer)) ||  (Array.isArray(x.idAnswer) && x.idAnswer.length > 0)).length}<span className="rotate">&nbsp;</span>{answers.length}</div>
                    <form className="test-form">
                      {data !== 'loading' && data.questions &&
                        <div className="test-form__inner">
                          {(data.questions as ItemQuestionType[]).map((item, index) => 
                            <Components.ItemQuestion 
                              item={item} 
                              index={index} 
                              key={index}
                              handleAnswer={checkAnswers}
                            />
                          )}
                        </div>
                      }
                      <button 
                        className="btn --submit" 
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.preventDefault();
                          Swal.fire({
                            title: answers.filter(x => (x.idAnswer === null || (Array.isArray(x.idAnswer) && x.idAnswer.length === 0))).length > 0
                              ? "You haven't completed this test. \n Are you sure you want to submit it?"
                              : 'Are you sure you want to submit it?'
                            ,
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            confirmButtonColor: '#0069bd',
                            customClass: {
                              title: 'font-title-popup'
                            }
                          }).then((result) => {
                            if (result.isConfirmed) {
                              onSubmitTest();
                            }
                          })
                        }}
                      >SUBMIT</button>
                    </form>
                </div>
              }
            </div>
          }
        </>
      }
    </div>
  );
};