import {useGoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
// const refreshTokenHandle = (res: GoogleLoginResponse) => {
//     let refreshTiming = (res.tokenObj.expires_in || 3600) * 1000;
//     const refreshToken = async () => {
//         const newAuthRes = await res.reloadAuthResponse();
//         refreshTiming = (newAuthRes.expires_in || 3600) * 1000;;
//         setTimeout(refreshToken, refreshTiming)
//     }
//     setTimeout(refreshToken, refreshTiming)
// }
type LoginButtonType = {
    setError: React.Dispatch<React.SetStateAction<{name: string | null, email: string | null}>>,
    setName: React.Dispatch<React.SetStateAction<{
        name: string;
        email: string;
    }>>
    className?: string,
}
export const LoginBtn = (props: LoginButtonType) => {
    const clientId = '85460187449-medlnuqreev8onc2nb42eonpcho7q47b.apps.googleusercontent.com';
    const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        props.setName({
            name: (res as GoogleLoginResponse).profileObj.name,
            email: (res as GoogleLoginResponse).profileObj.email
        });
        props.setError({name: null, email: null});

    }
    const onFailure = (res: any) => {
        console.log('onFailure', res)
    }
    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: false,
    });
    return <button className={`btn --google ${props.className ?? ''}`} onClick={signIn}>Login Google</button>
}