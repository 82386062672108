import { useEffect, useRef } from 'react';
export const Quiz = (props: {
  data: any,
  setPoint: React.Dispatch<React.SetStateAction<{
    type: 'QUIZ' | 'GAME';
    point: number
} | null>>,
setStartTime: React.Dispatch<React.SetStateAction<number>>
}) => {
  const refQuiz = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if(refQuiz && refQuiz.current) {
      var iframe = refQuiz.current.querySelector('iframe');
      iframe?.contentWindow?.addEventListener('load', function() {
        window.scrollTo({ top: iframe ? iframe.getBoundingClientRect().y - 100 : 0, behavior: 'smooth' });
        console.log(props.data)
        iframe?.contentWindow?.postMessage({
          type: 'data',
          content: props.data
        })
      })
      window.addEventListener("message", (event) => {
        if(event.data && event.data.type === 'startQuiz') {
          props.setStartTime(new Date().getTime());
        }
        if(event.data && event.data.type === 'submit') {
          props.setPoint({
            type: 'QUIZ',
            point: event.data.content ?? 0
          })
        }
      });
    }
  })
  return (
    <main className="main">
        <div ref={refQuiz} id="tmp_quiz" dangerouslySetInnerHTML={{__html: '<iframe src="/quiz-new/" width="540" height="450"></iframe>'}} />
    </main>
  )
}