import React from 'react';
import ReactDOM from 'react-dom/client';
import * as ReactRouter from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import * as Screens from './screens';
import * as Components from './components';
import { Route } from './routes';

import './assets/scss/common.scss'
import reportWebVitals from './reportWebVitals';

const ScrollToTop = () => {
  const { pathname } = ReactRouter.useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <ReactRouter.Routes>
      <ReactRouter.Route element={<Components.MainLayout />}>
        <ReactRouter.Route path={Route.NotFound.path} element={<Screens.NotFound />} />
        <ReactRouter.Route path={Route.Home.path} element={<Screens.Home />} />
        <ReactRouter.Route path={Route.HomeQuiz.path} element={<Screens.Home />} />
        <ReactRouter.Route path={Route.HomeSurvey.path} element={<Screens.Home />} />
        <ReactRouter.Route path={Route.HomeGame.path} element={<Screens.Home />} />
      </ReactRouter.Route>
    </ReactRouter.Routes>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRoutes />
    </BrowserRouter>
  );
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();