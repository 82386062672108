import * as Components from '../components';
import React from 'react';
import iconChrome from '../assets/images/icon/icon-chrome.svg';
import iconEdge from '../assets/images/icon/icon-edge.svg';
import iconFF from '../assets/images/icon/icon-ff.svg';
import iconSafari from '../assets/images/icon/icon-safari.svg';

export const UserInformation = 
  (props : {
    type: 'input' | 'label', 
    error: {name: string | null, email: string | null}, 
    name: {name: string, email: string},
    setName: React.Dispatch<React.SetStateAction<{name: string, email: string}>>,
    setError: React.Dispatch<React.SetStateAction<{name: string | null, email: string | null}>>
  }) => {
  return (
    <div className={`information${props.type === 'label' ? ' --test' : ''}`}>
      <h2>Candidate Information</h2>
      <div className="information__form">
        <div className="flex flex-wrap sm:flex-nowrap">
          <Components.InputForm id="name" label="Full name" required={true} className="sm:w-2/4 w-full sm:mr-10" error={(props.error && props.error.name)?? ''}>
            <input
              id="name"
              maxLength={50}
              name="title"
              className={"form-input__text"}
              placeholder="Your full name"
              disabled={props.type === 'label'}
              value={props.name.name}
              required={true}
              onChange={(value) => props.setName({...props.name, name: value.target.value})}
              onBlur={(e) => {
                if(!e.target.value || e.target.value === '') return props.setError({...props.error, name: 'Please enter name'})
                return props.setError({...props.error, name: null})
              }}
            />
          </Components.InputForm>
          <Components.InputForm id="email" label="Email" required={true} className="sm:w-2/4 w-full" error={(props.error && props.error.email)?? ''}>
            <input
              id="email"
              name="title"
              className={"form-input__text"}
              placeholder="Your email"
              disabled={props.type === 'label'}
              required={true}
              type="email"
              value={props.name ? props.name.email : undefined}
              onChange={(value) => props.setName({...props.name, email: value.target.value})}
              onBlur={(e) => {
                const matchEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                if(!matchEmail.test(e.target.value)) return props.setError({...props.error, email: 'Please enter in the correct format'})
                return props.setError({...props.error, email: null})
              }}
            />
          </Components.InputForm>
        </div>
        {props.type === 'input' && 
          <>
            <p className="text-center text-sm text-zinc-500	italic sm:mt-6 mt-4">You will receive the results in this email</p>
            <Components.LoginBtn setName={props.setName} setError={props.setError} className={'sm:mt-10 mt-7'}/>
            <p className="text-center text-sm text-zinc-500	italic sm:mt-6 mt-4">Supporting Browser </p>
            <ul className="flex justify-center mt-3.5">
              <li className="mx-1"><img src={iconSafari} alt="Safari" width="32" height="32" /></li>
              <li className="mx-1"><img src={iconFF} alt="FireFox" width="32" height="32" /></li>
              <li className="mx-1"><img src={iconChrome} alt="chrome" width="32" height="32" /></li>
              <li className="mx-1"><img src={iconEdge} alt="Edge" width="32" height="32" /></li>
            </ul>
          </>
        }
      </div>
    </div>
  )
}