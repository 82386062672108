type CreatorInputWrapperProps = {
    id? : string, 
    label?: string, 
    required? : boolean,
    error?: string,
    className? : string,
    children: JSX.Element | JSX.Element[] | null,
}
export const InputForm = (props : CreatorInputWrapperProps):JSX.Element => {
    return <div className={"form-input__controls" + (props.className ? " " + props.className : "")}>
    {props.label && <label htmlFor={props.id ? props.id : ''} className="form-label form-input__label">{props.label}{props.required && <span className="form-input__required">*</span>}</label>}
    {props.children}
    {props.error && <p className="form-input__error text-red-600">{props.error}</p>}
  </div>
}