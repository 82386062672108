import { RequestSubmitAnswerBody } from "../entity/question";

export class Api {
  private host = 'https://test.globaldesignit.vn/wp-json/';
  private request = async <T>(type: 'POST' | 'GET', url: string, body?: any): Promise<T | 'error'> => {
    const requestUrl = this.host + url

    if (process.env.NODE_ENV === 'development') console.log('[API REQUEST]' + requestUrl, body)

    return fetch(requestUrl, body !== undefined ? {
      method: type,
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify(body)
    } : {
      method: type
    })
      .then((response : any) => {
        return response.json()
      })
      .then((response : T) => {
        if (process.env.NODE_ENV === 'development') console.log('[API RESPONSE]' + requestUrl, response)
        return response
      })
      .catch((error : Error) => {
        if (process.env.NODE_ENV === 'development') console.log('[API ERROR]' + requestUrl, error)
        return 'error'
      })
  }

  private get = <T>(url: string): Promise<T | 'error'> => {
    return this.request('GET', url)
  }

  private post = <T>(url: string, body?: any): Promise<T | 'error'> => {
    return this.request('POST', url, body)
  }

  getTest = (testId: string, name: string, email: string):Promise<any> => this.get('tests/getById/'+testId+`?name=${name}&email=${email}`);
  postTest = (body: RequestSubmitAnswerBody):Promise<any> => this.post('tests/submitResult/', body)
}

export const api = new Api()
