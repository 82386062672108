import React from 'react';
import * as Components from '../components';
import * as ReactRouter from "react-router-dom";

export const Home = () => {
  const [startTest, setStartTest] = React.useState<boolean>(false);
  const testId = ReactRouter.useParams();
  const [name, setName] = React.useState<{name: string, email: string}>({name: '', email: ''});
  const [error, setError] = React.useState<{name: string | null, email: string | null}>({name: null, email: null});
  const [status, setStatus] = React.useState<'PROCESSING' | 'INIT' | 'SUCCESS' | 'FAILED'> ('INIT');
  const backTopRef = React.useRef<HTMLButtonElement | null>(null);
  let disabledTest;
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if(name.name !== '' && name.email !== '' && error.name === null && error.email === null) {
      window.scrollTo(0,0);
      setStartTest(true);
    }
  }
  if(testId && testId.id && !startTest) {
   disabledTest = localStorage.getItem('tested_'+testId.id);
  }
return (
   <>
    {status === 'SUCCESS'
      ? <Components.Completed />
      : <>
        <main className="main">
            <div id="tmp_home" className={`${startTest ? '': 'view-bg'}${status === 'PROCESSING' ? ' --hidden' : ''}`}>
                <div className={`first-view ${startTest ? '--test' : ''}`}>&nbsp;</div>
                <Components.UserInformation 
                  type={`${disabledTest === 'true' || startTest ? 'label' : 'input'}`} 
                  error={error} 
                  setError={setError}
                  name={name}
                  setName={setName}
                />
                {!startTest
                ? <div className="btn-wrap">
                    <p className="text-center">Press the START button to do test</p>
                    <button className="btn --submit" type="submit" onClick={e => onSubmit(e)} disabled={disabledTest === 'true' ||name.name === '' || name.email === ''}>START</button>
                  </div>
                : <Components.Test {...name} status={status} setStatus={setStatus} backTopRef={backTopRef}/>
                } 
            </div>
          </main>
        <Components.Footer backTopRef={backTopRef} className={`${startTest ? '--test' : ''}`}/>
      </>
    }
      
   </>
  );
};