import { Outlet } from 'react-router-dom';
import * as Components from '../components';
import * as ReactRouter from "react-router-dom";

export const MainLayout = () => {
  const location = ReactRouter.useLocation();
  const {pathname} = location;
  return (
    <div id="tmp_wrapper" className={`${pathname.indexOf('quiz') !== -1 || pathname.indexOf('game') !== -1 ? 'fix-width' : ''}`}>
      <Components.Header />
      <Outlet />
    </div>
  )
}